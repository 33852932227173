




















import { Component, Vue } from 'vue-property-decorator';
import Page from '@/components/partials/Page.vue';

@Component({
  components: {
    Page,
  },
  metaInfo: {
    title: 'innovaMo - digitaler Mobilitäsmarktplatz',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: '',
      },
    ],
  },
})
export default class DeveloperAccounInfo extends Vue {

}
